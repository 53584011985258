/**
 * @generated SignedSource<<c05eb0066b93e18f8b25ce3961666a11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRespRelatedItem_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTile_viewer">;
  readonly " $fragmentType": "SharedRespRelatedItem_viewer";
};
export type SharedRespRelatedItem_viewer$key = {
  readonly " $data"?: SharedRespRelatedItem_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRespRelatedItem_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedRespRelatedItem_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemTile_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e3167b325cae357283bc02b3e8e1f914";

export default node;
