import { type FC, type MouseEventHandler } from 'react';
import { graphql, useFragment } from 'react-relay';
import FavoritesItemProvider from 'dibs-portfolio-folder/exports/FavoritesItemProvider';
import { ItemTile } from 'dibs-search-product-tile/exports/ItemTile';
import { FavoritesItem } from '../../components/global/FavoriteIcons/FavoritesItem';
import { VERTICAL_OFFSET_RESP_IMG, SRC_SET_SIZES_TILE } from '../../constants/imageConstants';
import classNames from 'classnames';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { useQuickView } from 'dibs-search-product-tile/exports/QuickViewProvider';

import styles from './SharedRespRelatedItem.scss';

import { type SharedRespRelatedItem_item$key } from './__generated__/SharedRespRelatedItem_item.graphql';
import { type SharedRespRelatedItem_user$key } from './__generated__/SharedRespRelatedItem_user.graphql';
import { type SharedRespRelatedItem_viewer$key } from './__generated__/SharedRespRelatedItem_viewer.graphql';
import { type FavoritesProviderChildrenProps } from '../../components/global/FavoriteIcons/FavoritesProviderChildrenProps';

const SMALL_TILE_TYPE = 'small';
export const X_SMALL_TILE_TYPE = 'xSmall';
export type itemTileType = typeof X_SMALL_TILE_TYPE | typeof SMALL_TILE_TYPE;

type Props = {
    item: SharedRespRelatedItem_item$key;
    user: SharedRespRelatedItem_user$key | null | undefined;
    viewer: SharedRespRelatedItem_viewer$key | null | undefined;
    customClassName?: string | null;
    index: number;
    currency: string;
    onClick?: MouseEventHandler;
    favorites: FavoritesProviderChildrenProps;
    lazyLoadStartIndex?: number;
    showLightBox?: boolean;
    showMeasurements?: boolean;
    hideTileImagePadding?: boolean;
    tileType?: itemTileType;
    badgeBgColor?: string;
    showNoReserveBadge?: boolean;
};

export const SharedRespRelatedItem: FC<Props> = ({
    index,
    customClassName,
    item: itemRef,
    onClick,
    user: userRef,
    viewer: viewerRef,
    favorites,
    currency,
    lazyLoadStartIndex = 0,
    showLightBox = false,
    showMeasurements,
    hideTileImagePadding,
    tileType = SMALL_TILE_TYPE,
    badgeBgColor,
    showNoReserveBadge,
}) => {
    const viewer = useFragment(
        graphql`
            fragment SharedRespRelatedItem_viewer on Viewer {
                ...ItemTile_viewer
            }
        `,
        viewerRef
    );
    const item = useFragment(
        graphql`
            fragment SharedRespRelatedItem_item on Item
            @argumentDefinitions(priceBookName: { type: "String" }) {
                isFurniture
                firstPhotoWebPath(size: thumb)
                ...ItemTile_item @arguments(isTrade: $isTrade, priceBookName: $priceBookName)
                ...FavoritesItem_item
                ...FavoritesItemProvider_item
            }
        `,
        itemRef
    );
    const user = useFragment(
        graphql`
            fragment SharedRespRelatedItem_user on User {
                canShowSellerName
                ...FavoritesItemProvider_user
            }
        `,
        userRef
    );

    const { openQuickView, closeQuickView } = useQuickView();
    const { canShowSellerName } = user || {};
    const { firstPhotoWebPath, isFurniture } = item || {};
    const canShowCreatorName = !canShowSellerName && isFurniture;
    const { isTablet, isBot = false } = useServerVarsContext();

    const isClient = useClientState();

    // Default Layout
    return (
        <div className={classNames(styles.item, styles[tileType], customClassName)}>
            <FavoritesItemProvider item={item} user={user} applyMouseEvents>
                {({ isFolderVisible, ...favoritesItemProps }) => {
                    const favoritesComponent = favorites ? (
                        <div className={classNames(styles.favorites, styles[tileType])}>
                            <FavoritesItem
                                wrapperClass={styles.favoritesWrapper}
                                heartIconWrapperClass={styles.favoritesIconWrapper}
                                folderIconWrapperClass={styles.favoritesIconWrapper}
                                {...favorites}
                                item={item}
                                itemImage={firstPhotoWebPath || ''}
                                fetchFolder={favorites.fetchFolder && !!isFolderVisible}
                                {...favoritesItemProps}
                            />
                        </div>
                    ) : null;

                    return (
                        <ItemTile
                            disableContentMargin={tileType === X_SMALL_TILE_TYPE}
                            index={index}
                            item={item}
                            currency={currency}
                            itemsPerRow={lazyLoadStartIndex}
                            showSellerName={canShowSellerName}
                            showCreatorName={canShowCreatorName}
                            onClick={onClick}
                            showQuickView={!!openQuickView && !!closeQuickView}
                            showLightBox={showLightBox}
                            alignCenter={showLightBox}
                            disableBorder
                            useLoFiLazyLoader
                            imageSize={
                                tileType === X_SMALL_TILE_TYPE ? 'imageXSmall' : 'imageSmall'
                            }
                            itemSearch={null}
                            imageLoadVerticalOffset={VERTICAL_OFFSET_RESP_IMG}
                            viewer={viewer}
                            srcSetSizes={SRC_SET_SIZES_TILE}
                            showMSKUSwatch={false}
                            favoritesComponent={favoritesComponent}
                            showMeasurements={showMeasurements}
                            hideImagePadding={hideTileImagePadding}
                            badgeBgColor={badgeBgColor}
                            showNoReserveBadge={showNoReserveBadge}
                            showPrice={isClient || isBot}
                            isMobile={isTablet}
                        />
                    );
                }}
            </FavoritesItemProvider>
        </div>
    );
};
