/**
 * @generated SignedSource<<423a10b7d5ec7ff9b7f121d0eda5755d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRespRelatedItem_user$data = {
  readonly canShowSellerName: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_user">;
  readonly " $fragmentType": "SharedRespRelatedItem_user";
};
export type SharedRespRelatedItem_user$key = {
  readonly " $data"?: SharedRespRelatedItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRespRelatedItem_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedRespRelatedItem_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canShowSellerName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItemProvider_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1707aa45507ff0ef48a4e47064aa3312";

export default node;
