import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import {
    SharedRelatedItemsWrapper,
    type ChildrenProps as GridProps,
} from '../SharedRelatedItemsWrapper/SharedRelatedItemsWrapper';
import { SharedRespRelatedItem } from '../SharedRespRelatedItem/SharedRespRelatedItem';
import { SharedMobileRelatedItem } from '../SharedMobileRelatedItem/SharedMobileRelatedItem';
import { youMayAlsoLike as youMayAlsoLikeHeader } from '../sharedMessages';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { useUserSessionRegions } from 'dibs-regional-info/exports/regionalInfoHelpers';
import {
    trackItemsImpression,
    trackItemClick,
    type Item as ItemTrackProps,
} from '../SharedRelatedItemsWrapper/helpers/sharedRelatedItemsWrapperTracking';

import { type SharedYMAL_itemSearch$key } from './__generated__/SharedYMAL_itemSearch.graphql';
import { type SharedYMAL_user$key } from './__generated__/SharedYMAL_user.graphql';

type Props = {
    itemSearch: SharedYMAL_itemSearch$key;
    user: SharedYMAL_user$key;
    isMobile: boolean;
    isClient: boolean;
};

const SharedYMAL: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
    isMobile,
    isClient,
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SharedYMAL_itemSearch on ItemSearchQueryConnection
            @argumentDefinitions(priceBookName: { type: "String" }) {
                pageType
                youMayAlsoLike(first: 20) {
                    edges {
                        node {
                            item {
                                serviceId
                                ...SharedRelatedItemsWrapper_item
                                ...SharedMobileRelatedItem_item
                                    @arguments(priceBookName: $priceBookName)
                                ...SharedRespRelatedItem_item
                                    @arguments(priceBookName: $priceBookName)
                                ...sharedRelatedItemsWrapperTracking_item
                            }
                        }
                    }
                }
            }
        `,
        itemSearchRef
    );

    const user = useFragment(
        graphql`
            fragment SharedYMAL_user on User {
                ...SharedRelatedItemsWrapper_user
                ...SharedMobileRelatedItem_user
                ...SharedRespRelatedItem_user
            }
        `,
        userRef
    );

    const { youMayAlsoLike, pageType } = itemSearch;
    const items = youMayAlsoLike?.edges?.map(edge => edge?.node?.item).filter(filterFalsy) || [];
    const userRegions = useUserSessionRegions() || [];

    const onItemClick = ({ item, index }: { item: ItemTrackProps; index: number }): void => {
        trackItemClick({
            items: [item],
            startIndex: index,
            list: `${pageType} you may also like`,
            userRegions,
        });
    };

    if (!items.length) {
        return null;
    }

    return (
        <SharedRelatedItemsWrapper
            user={user}
            header={youMayAlsoLikeHeader}
            isMobile={isMobile}
            isClient={isClient}
            items={items}
            onItemsImpression={({ startIndex, itemsLengthToShow }) => {
                if (items?.length) {
                    trackItemsImpression({
                        items: [...items].splice(startIndex, itemsLengthToShow),
                        startIndex,
                        list: `${pageType} you may also like`,
                        userRegions,
                    });
                }
            }}
            isDark
        >
            {({ itemClassName, ...itemProps }: GridProps) =>
                items.map((item, index) => {
                    return isMobile ? (
                        <SharedMobileRelatedItem
                            key={item.serviceId}
                            index={index}
                            item={item}
                            user={user}
                            ymalHeartsEnabled
                            showLightBox
                            onClick={() => onItemClick({ item, index })}
                            {...itemProps}
                            {...itemClassName(index)}
                        />
                    ) : (
                        <SharedRespRelatedItem
                            key={item.serviceId}
                            index={index}
                            item={item}
                            user={user}
                            showLightBox
                            viewer={null}
                            onClick={() => onItemClick({ item, index })}
                            {...itemProps}
                            {...itemClassName(index)}
                        />
                    );
                })
            }
        </SharedRelatedItemsWrapper>
    );
};

export default SharedYMAL;
